import React from 'react';
import { connect } from 'react-redux';
import { pushLocation } from '@pearlchain/component-lib-common';
import { LoginForm } from "@pearlchain/stackbase-common"

interface Props {
    pushLocation: typeof pushLocation
}

function LoginPage(props: Props) {
    return <LoginForm onLoginSuccess={() => {
        // login complete. Redirect to home page
        props.pushLocation('/', 'Home', {})
    }}/>
}

export default connect(undefined, { pushLocation })(LoginPage);