import {
    calculateReorderPointOrForecast,
    createSimulationInstance,
    createSimulationTemplate,
    deleteSimulationTemplate,
    fetchAuditLogs,
    fetchForecastTimeSeries,
    findForecastGroups,
    findForecastLines,
    findForecastLinesByRop,
    findPartners,
    findProducts,
    findSalesOrders,
    findSimulationInstance,
    findSimulationInstances,
    findSimulationTemplate,
    findSimulationTemplates,
    invalidateSimulationInstance,
    listABCClassifications,
    listCompanies,
    listForecastAlgos,
    listForecastROPSchemes,
    listForecastROPSchemeTypes,
    listInventoryPlanners,
    listPlanningStates,
    listPlants,
    listProductGroups,
    listProductStatuses,
    listSimulationTemplates,
    listSimulationTypes,
    listWarehouses,
    publishSimulationInstance,
    saveForecastGroupLines,
    saveForecastGroups,
    updateReorderPoints,
    updateSalesOrders,
    updateSimulationTemplate
} from './remoting/api';

import { findReorderPoints } from './remoting/handlers/reorderPointHandlers';
import { findForecastAccuracyDetails, findForecastAccuracyOverview } from './remoting/handlers/forecastAccuracyRequests';
import RequestBuilder from './remoting/RequestBuilder';

export type VcoRequestHandler = (requestBuilder: RequestBuilder, ...args: any[]) => Promise<any>;

export enum RequestId {
    FIND_SIMULATION_INSTANCES = 'simulation-instances',
    FIND_SIMULATION_INSTANCES_FILTERED = 'simulation-instances-filtered',
    FIND_REORDER_POINTS = 'reorder-points',
    FIND_FORECAST_GROUPS = 'find-forecast-groups',
    SAVE_FORECAST_LINES = 'save-forecast-lines',
    LIST_COMPANIES = 'list-companies',
    LIST_SIMULATION_TEMPLATES = 'list-simulation-templates',
    FIND_SIMULATION_TEMPLATE = 'find-simulation-template',
    FIND_SIMULATION_INSTANCE = 'find-simulation-instance',
    LIST_SIMULATION_TYPES = 'list-simulation-types',
    LIST_WAREHOUSES = 'list-warehouses',
    LIST_FORECASTROP_SCHEMES = 'list-forecastrop-schemes',
    LIST_FORECASTROP_SCHEME_TYPES = 'list-forecastrop-type-schemes',
    LIST_INVENTORY_PLANNERS = 'list-inventory-planners',
    LIST_PLANTS = 'list-plants',
    LIST_ABCCLASSIFICATIONS = 'list-abcclassifications',
    LIST_FORECAST_ALGOS = 'list-forecast-algos',
    LIST_PRODUCT_GROUPS = 'list-product-groups',
    LIST_PRODUCT_STATUSES = 'list-product-statuses',
    LIST_PLANNING_STATES = 'list-planning-states',
    FIND_PARTNERS = 'find-partners',
    FIND_PRODUCTS = 'find-products',
    FIND_FORECAST_LINES = 'find-forecast-lines',
    FIND_FORECAST_LINES_BY_ROP = 'find-forecast-lines-by-rop',
    FIND_SIMULATION_TEMPLATES = 'find-simulation-templates',
    CREATE_SIMULATION_INSTANCE = 'create-simulation-instance',
    CREATE_SIMULATION_TEMPLATE = 'create-simulation-template',
    UPDATE_SIMULATION_TEMPLATE = 'update-simulation-template',
    INVALIDATE_SIMULATION_INSTANCE = 'invalidate-simulation-instance',
    PUBLISH_SIMULATION_INSTANCE = 'publish-simulation-instance',
    CALCULATE_PARTIAL_REORDERPOINT_OR_FORECAST = 'calculate-partial-reorderpoint-or-forecast',
    CALCULATE_REORDERPOINT_OR_FORECAST = 'calculate-reorderpoint-or-forecast',
    UPDATE_REORDER_POINTS = 'update-reorder-points',
    FETCH_AUDIT_LOGS = 'fetch-audit-logs',
    FORECAST_TIME_SERIES = 'forecast-time-series',
    FIND_SALES_ORDERS = 'find-sales-orders',
    UPDATE_SALES_ORDERS = 'update-sales-orders',
    FIND_FORECAST_ACCURACY_OVERVIEW = 'forecast-accuracy-overview',
    FIND_FORECAST_ACCURACY_DETAILS = 'forecast-accuracy-details',
    UPDATE_FORECAST_GROUPS = 'update-forecast-groups',
    DELETE_SIMULATION_TEMPLATE = 'delete-simulation-template'
}

/**
 * mapping between RequestId and request handler
 */

export const RequestHandlers = {
    [RequestId.FIND_SIMULATION_INSTANCES]: findSimulationInstances,
    [RequestId.FIND_SIMULATION_INSTANCES_FILTERED]: findSimulationInstances,
    [RequestId.FIND_REORDER_POINTS]: findReorderPoints,
    [RequestId.FIND_FORECAST_GROUPS]: findForecastGroups,
    [RequestId.SAVE_FORECAST_LINES]: saveForecastGroupLines,
    [RequestId.LIST_COMPANIES]: listCompanies,
    [RequestId.LIST_SIMULATION_TEMPLATES]: listSimulationTemplates,
    [RequestId.LIST_SIMULATION_TYPES]: listSimulationTypes,
    [RequestId.LIST_WAREHOUSES]: listWarehouses,
    [RequestId.LIST_FORECASTROP_SCHEMES]: listForecastROPSchemes,
    [RequestId.LIST_FORECASTROP_SCHEME_TYPES]: listForecastROPSchemeTypes,
    [RequestId.LIST_INVENTORY_PLANNERS]: listInventoryPlanners,
    [RequestId.LIST_PLANTS]: listPlants,
    [RequestId.LIST_ABCCLASSIFICATIONS]: listABCClassifications,
    [RequestId.LIST_FORECAST_ALGOS]: listForecastAlgos,
    [RequestId.LIST_PRODUCT_GROUPS]: listProductGroups,
    [RequestId.LIST_PRODUCT_STATUSES]: listProductStatuses,
    [RequestId.LIST_PLANNING_STATES]: listPlanningStates,
    [RequestId.FIND_PARTNERS]: findPartners,
    [RequestId.FIND_PRODUCTS]: findProducts,
    [RequestId.FIND_FORECAST_LINES]: findForecastLines,
    [RequestId.FIND_SIMULATION_TEMPLATES]: findSimulationTemplates,
    [RequestId.FIND_FORECAST_LINES_BY_ROP]: findForecastLinesByRop,
    [RequestId.CREATE_SIMULATION_INSTANCE]: createSimulationInstance,
    [RequestId.CREATE_SIMULATION_TEMPLATE]: createSimulationTemplate,
    [RequestId.UPDATE_SIMULATION_TEMPLATE]: updateSimulationTemplate,
    [RequestId.DELETE_SIMULATION_TEMPLATE]: deleteSimulationTemplate,
    [RequestId.FIND_SIMULATION_TEMPLATE]: findSimulationTemplate,
    [RequestId.FIND_SIMULATION_INSTANCE]: findSimulationInstance,
    [RequestId.INVALIDATE_SIMULATION_INSTANCE]: invalidateSimulationInstance,
    [RequestId.PUBLISH_SIMULATION_INSTANCE]: publishSimulationInstance,
    [RequestId.CALCULATE_REORDERPOINT_OR_FORECAST]: calculateReorderPointOrForecast,
    [RequestId.UPDATE_REORDER_POINTS]: updateReorderPoints,
    [RequestId.FETCH_AUDIT_LOGS]: fetchAuditLogs,
    [RequestId.FORECAST_TIME_SERIES]: fetchForecastTimeSeries,
    [RequestId.FIND_SALES_ORDERS]: findSalesOrders,
    [RequestId.UPDATE_SALES_ORDERS]: updateSalesOrders,
    [RequestId.FIND_FORECAST_ACCURACY_OVERVIEW]: findForecastAccuracyOverview,
    [RequestId.FIND_FORECAST_ACCURACY_DETAILS]: findForecastAccuracyDetails,
    [RequestId.UPDATE_FORECAST_GROUPS]: saveForecastGroups
};
