import { RequestBuilder, HttpRequestMethod } from '@pearlchain/component-lib-common';
import { CheckSalesForecastGroupDuplicatesParams, FetchAuditLogRequestParams, SaveForecastGroupLinesParams } from './requestTypes';

export const URL = {
    LIST_COMPANIES: 'sec/api/masterdata/companies',
    LIST_PLANTS: 'sec/api/masterdata/plants',
    LIST_ABCCLASSIFICATIONS: 'sec/api/masterdata/abcClassifications',
    LIST_WAREHOUSES: 'sec/api/masterdata/warehouses/',
    FIND_PARTNER: 'sec/api/masterdata/find/partner',

    FIND_FORECAST_GROUPS: 'sec/api/forecasting/forecast-groups/find',
    SAVE_FORECAST_GROUPS: 'sec/api/forecasting/forecast-groups/actions/update',
    FIND_FORECAST_LINES: 'sec/api/forecasting/forecast-lines/find',
    FIND_FORECAST_LINES_BY_ROP: 'sec/api/forecasting/forecast-lines/findbyrop',
    SAVE_FORECAST_LINES: 'sec/api/forecasting/forecast-lines/save',
    LIST_FORECASTROP_SCHEMES: 'sec/api/forecasting/forecastropschemes',
    LIST_FORECASTROP_SCHEME_TYPES: 'sec/api/forecasting/forecastropschemetypes',
    LIST_FORECAST_ALGOS: 'sec/api/forecasting/forecastalgos',
    CREATE_FORECAST_LINES_MANUAL: 'sec/api/forecasting/forecast-lines/create-manual',
    CHECK_FORECAST_GROUP_DUPLICATES: 'sec/api/forecasting/forecast-groups/check-duplicates',
    CALCULATE_REORDER_POINT_OR_FORECAST: 'sec/api/forecasting/calculate',
    FORECAST_TIME_SERIES: 'sec/api/forecasting/time-series',

    LIST_PRODUCT_GROUPS: 'sec/api/vco/product/groups',
    LIST_PRODUCT_STATUSES: 'sec/api/vco/product/statuses',
    LIST_INVENTORY_PLANNERS: 'sec/api/vco/product/inventory-planners',
    FIND_PRODUCTS: 'sec/api/vco/product/find',

    SIMULATION_INSTANCES: 'sec/api/simulation/instances/',
    SIMULATION_INSTANCES_PUBLISH: 'sec/api/simulation/instances/publish/',
    SIMULATION_INSTANCES_DELETE: 'sec/api/simulation/instances-delete/',
    FIND_SIMULATION_INSTANCES: 'sec/api/simulation/instances/find',
    SIMULATION_TEMPLATES: 'sec/api/simulation/templates/',
    UPDATE_SIMULATION_TEMPLATES: 'sec/api/simulation/templates/update',
    DELETE_SIMULATION_TEMPLATES: 'sec/api/simulation/templates/delete/',
    FIND_SIMULATION_TEMPLATES: 'sec/api/simulation/templates/find',
    SIMULATION_TYPES: 'sec/api/simulation/types/',
    LIST_PLANNING_STATES: 'sec/api/simulation/planning-states',

    FIND_REORDER_POINTS: 'sec/api/reorderpoint/find',
    UPDATE_REORDER_POINTS: 'sec/api/reorderpoint/update',
    FETCH_AUDIT_LOGS: 'sec/api/auditlog/find',
    FIND_SALES_ORDERS: 'sec/api/sales-orders/find',
    SAVE_SALES_ORDERS: 'sec/api/sales-orders/save-forecasting'
};

export function saveForecastGroupLines(requestBuilder: RequestBuilder, params: [SaveForecastGroupLinesParams]) {
    return requestBuilder
        .post(URL.SAVE_FORECAST_LINES)
        .setBodyJson(params)
        .fetch();
}


export function createForecastLinesManual(requestBuilder: RequestBuilder, productNo: any, customerNo: any, company: any, plant: string, lines: any) {
    return requestBuilder
        .post(URL.CREATE_FORECAST_LINES_MANUAL)
        .setBodyJson({
            productNo,
            customerNo,
            company,
            plant,
            lines
        })
        .fetch();
}

export function checkSalesForecastGroupDuplicates(requestBuilder: RequestBuilder, params: CheckSalesForecastGroupDuplicatesParams) {
    return requestBuilder
        .post(URL.CHECK_FORECAST_GROUP_DUPLICATES)
        .setBodyJson(params)
        .toJS();
}

export function createSimulationInstance(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.SIMULATION_INSTANCES)
        .setBodyJson(params)
        .fetch();
}

export function createSimulationTemplate(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.SIMULATION_TEMPLATES)
        .setBodyJson(params)
        .fetch();
}

export function updateSimulationTemplate(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.UPDATE_SIMULATION_TEMPLATES)
        .setBodyJson(params)
        .fetch();
}

export function deleteSimulationTemplate(requestBuilder: RequestBuilder, templateUid: string) {
    return requestBuilder
        .post(URL.DELETE_SIMULATION_TEMPLATES + templateUid)
        .toJS();
}

export function findSimulationTemplate(requestBuilder: RequestBuilder, templateUid: string) {
    return listItems(requestBuilder, URL.SIMULATION_TEMPLATES + templateUid, HttpRequestMethod.GET);
}

export function findSimulationInstance(requestBuilder: RequestBuilder, instanceUid: string) {
    return listItems(requestBuilder, URL.SIMULATION_INSTANCES + instanceUid, HttpRequestMethod.GET);
}

export function invalidateSimulationInstance(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.SIMULATION_INSTANCES_DELETE)
        .setBodyJson(params)
        .fetch();
}

export function publishSimulationInstance(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.SIMULATION_INSTANCES_PUBLISH)
        .setBodyJson(params)
        .toImmutable();
}

export function calculateReorderPointOrForecast(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.CALCULATE_REORDER_POINT_OR_FORECAST)
        .setBodyJson(params)
        .fetch();
}

export function updateReorderPoints(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.UPDATE_REORDER_POINTS)
        .setBodyJson(params)
        .fetch();
}

export function fetchAuditLogs(requestBuilder: RequestBuilder, params: FetchAuditLogRequestParams) {
    return queryItems(requestBuilder, params, URL.FETCH_AUDIT_LOGS);
}

export function fetchForecastTimeSeries(requestBuilder: RequestBuilder, params: any) {
    return queryItems(requestBuilder, params, URL.FORECAST_TIME_SERIES)
}

export function findSalesOrders(requestBuilder: RequestBuilder, params: any) {
    return queryItems(requestBuilder, params, URL.FIND_SALES_ORDERS);
}

export function updateSalesOrders(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.SAVE_SALES_ORDERS)
        .setBodyJson(params)
        .fetch();
}

export function findSimulationInstances(requestBuilder: RequestBuilder, params: any, excludeBasedOnPlanningDefaultTemplates: boolean = true) {
    let newParams = Object.assign({}, params);
    newParams.excludeBasedOnPlanningDefaultTemplates = excludeBasedOnPlanningDefaultTemplates;
    return queryItems(requestBuilder, newParams, URL.FIND_SIMULATION_INSTANCES);
}

export function findForecastGroups(requestBuilder: RequestBuilder, params: any) {
    return queryItems(requestBuilder, params, URL.FIND_FORECAST_GROUPS);
}

export function saveForecastGroups(requestBuilder: RequestBuilder, params: any) {
    return requestBuilder
        .post(URL.SAVE_FORECAST_GROUPS)
        .setBodyJson(params)
        .fetch();
}

export function listCompanies(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_COMPANIES);
}

export function listSimulationTemplates(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.SIMULATION_TEMPLATES, HttpRequestMethod.GET);
}

export function findSimulationTemplates(requestBuilder: RequestBuilder, params: any) {
    return queryItems(requestBuilder, params, URL.FIND_SIMULATION_TEMPLATES);
}

export function listSimulationTypes(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.SIMULATION_TYPES, HttpRequestMethod.GET);
}

export function listWarehouses(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_WAREHOUSES, HttpRequestMethod.GET);
}

export function listForecastROPSchemes(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_FORECASTROP_SCHEMES, HttpRequestMethod.GET);
}

export function listForecastROPSchemeTypes(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_FORECASTROP_SCHEME_TYPES, HttpRequestMethod.GET);
}

export function listInventoryPlanners(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_INVENTORY_PLANNERS, HttpRequestMethod.GET);
}

export function listPlants(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_PLANTS, HttpRequestMethod.GET);
}

export function listABCClassifications(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_ABCCLASSIFICATIONS, HttpRequestMethod.GET);
}

export function listForecastAlgos(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_FORECAST_ALGOS, HttpRequestMethod.GET);
}

export function listProductGroups(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_PRODUCT_GROUPS);
}

export function listProductStatuses(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_PRODUCT_STATUSES);
}

export function listPlanningStates(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_PLANNING_STATES, HttpRequestMethod.GET);
}

export function findPartners(requestBuilder: RequestBuilder, params: any) {
    return queryItems(requestBuilder, params, URL.FIND_PARTNER);
}

export function findProducts(requestBuilder: RequestBuilder, params: any) {
    return queryItems(requestBuilder, params, URL.FIND_PRODUCTS);
}

export function findForecastLines(requestBuilder: RequestBuilder, params: any) {
    return queryItems(requestBuilder, params, URL.FIND_FORECAST_LINES);
}

export function findForecastLinesByRop(requestBuilder: RequestBuilder, params: any) {
    return queryItems(requestBuilder, params, URL.FIND_FORECAST_LINES_BY_ROP);
}

export function queryItems(requestBuilder: RequestBuilder, params={}, url: string) {
    return requestBuilder
        .post(url)
        .setBodyJson(params)
        .toImmutable();
}

export function listItems(requestBuilder: RequestBuilder, url: string, method?: HttpRequestMethod) {
    return requestBuilder
        .setUrl(url)
        .setHttpMethod(method || HttpRequestMethod.POST)
        .toImmutable();
}
