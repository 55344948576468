import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAuthToken, getLanguage } from '@pearlchain/component-lib-common';


import { fetchProperties } from './propertyActions';
import { StoreState } from '../../types/storeTypes';
import { selectProperty } from './propertySelectors';

// TODO: defined in component-lib, but not currently exported
const PARAM_AUTH_TOKEN = 'auth-token';
const PARAM_LANGUAGE = 'language';

const PROP_URL_DASHBOARDS = 'urlDashboards';

type StateProps = {
    baseDashboardsUrl: string | undefined;
}

type DispatchProps = {
    fetchProperties: typeof fetchProperties
}

function resolveDashboardsUrl(baseUrl: string) {
    const searchParams = new URLSearchParams();
    searchParams.set(PARAM_AUTH_TOKEN, getAuthToken()!);
    searchParams.set(PARAM_LANGUAGE, getLanguage()!);

    let sepChar = '?';
    if (baseUrl.indexOf('?') >= 0) {
        sepChar = '&';
    }

    return baseUrl + sepChar + searchParams;
}

function HomePage(props: StateProps & DispatchProps) {
    // fetch properties when the page is mounted
    useEffect(() => {
        props.fetchProperties();
    }, [props.fetchProperties]);

    // wait for the properties to be loaded
    const baseDashboardsUrl = props.baseDashboardsUrl;
    if (baseDashboardsUrl == null) return null;

    // show the dashboards in an iframe
    const dashboardsUrl = resolveDashboardsUrl(baseDashboardsUrl);
    return <iframe src={dashboardsUrl} className="dashboards-iframe"/>;
}

export default connect<StateProps, DispatchProps, {}, StoreState>((state) => {
    return {
        baseDashboardsUrl: selectProperty(state, PROP_URL_DASHBOARDS)
    }
}, { fetchProperties })(HomePage);
