import { fromJS, List } from 'immutable';
import { Column } from '../../../types/columnTypes';

export const reorderPointOverviewColumns: List<Column> = fromJS([
    {_key: 'id', width: 0, idField: true},
    {_key: 'actions', width: 100, title: 'Actions', actions: [{
            cssClass: 'btn btn-primary fa',
            icon: 'fa-chart-line',
            eventName: 'action-show-forecast-details'
        }]},
    {_key: 'plant', width: 130, title: 'common.grid.plant'},
    {_key: 'productNo', width: 130, title: 'common.grid.productNo' },
    {_key: 'productDescription', width: 130, title: 'common.grid.productDesc' },
    {_key: 'forecastROPScheme', width: 130, title: 'common.grid.forecastROPScheme' },
    {_key: 'uom', width: 130, title: 'common.grid.uom'  },
    {_key: 'liveQuantity', width: 100, title: 'reorderPoints.master.column.liveQuantity', formatter: 'roundNumber'},
    {_key: 'algoQuantity', width: 100, title: 'reorderPoints.master.column.algoQuantity', formatter: 'roundNumber', hideInLive: true},
    {_key: 'quantity', width: 100, title: 'reorderPoints.master.column.quantity', editable: true, formatter: 'roundNumber', hideInLive: true},
    {_key: 'deviation', width: 130, title: 'reorderpoint.grid.deviation', formatter: 'number', hideInLive: true},
    {_key: 'posNeg', width: 50, title: 'common.grid.posNeg', hideInLive: true},
    {_key: 'leadTime', width: 80, title: 'reorderPoints.master.column.leadTime', formatter: 'roundNumber'},
    {_key: 'movingAverage', width: 80, title: 'reorderPoints.master.column.movingAverage', formatter: 'roundNumber'},
    {_key: 'liveSafetyStockQuantity', width: 80, title: 'reorderPoints.master.column.liveSafetyStockQuantity', formatter: 'roundNumber'},
    {_key: 'algoSafetyStockQuantity', width: 80, title: 'reorderPoints.master.column.algoSafetyStockQuantity', formatter: 'roundNumber', hideInLive: true},
    {_key: 'safetyStockQuantity', width: 80, title: 'reorderPoints.master.column.safetyStockQuantity', formatter: 'roundNumber', editable: true, hideInLive: true},
    {_key: 'remark', width: 130, title: 'common.grid.remark', editable: true},
    {_key: 'publishedDate', width: 130, title: 'common.grid.publishedDate', formatter: 'date' },
    {_key: 'toBePublished', width: 130, title: 'common.grid.toBePublished', type: 'checkbox', editable: true },
    {_key: 'processed', width: 130, title: 'common.grid.processed', type: 'checkbox', editable: true }
]);

export const COLUMN_DEVIATION = 12;
export const COLUMN_DEVIATION_KEY = 'deviation';
export const COLUMN_QUANTITY = 11;
export const COLUMN_QUANTITY_KEY = 'quantity';
export const COLUMN_SAFETY_STOCK_QUANTITY = 18;
export const COLUMN_SAFETY_STOCK_QUANTITY_KEY = 'safetyStockQuantity';
export const COLUMN_REMARK = 19;
export const COLUMN_TO_BE_PUBLISHED = 20;
export const COLUMN_PROCESSED = 21;

export const COLUMNS_META = new Map([
    ['forecastROPScheme', {
        translateValue: true
    }],
    ['uom', {
        translateValue: true
    }]
]);
