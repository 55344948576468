import React from 'react';
import { connect } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { Location, History } from 'history';
import Notifications, { warning } from 'react-notification-system-redux';
import { getAuthTokenPayload, TokenPayload } from '@pearlchain/component-lib-common';

import Navigation from './components/page/Navigation';
import i18n from './i18n';
import { StoreState } from './types/storeTypes';
import { pages, getInitialPageId } from './components/page/pages';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { SimpleStackNavigation, SubPage } from "@pearlchain/stackbase-common";

library.add(fas);

const EXPIRING_NOTIFICATION_GAP = 30000;

interface Props {
    warning: (notification: any) => void,
    location: Location,
    history: History,
    router: any,
    notifications: []
}

interface State {
    tokenExpiringTimerId?: any,
    notificationTimerId?: any
}

class App extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        if(i18n.isInitialized) {
            // force reload translations after login
            // (called after moving back from loginPage -> home)
            i18n.reloadResources();
        }

        // fetch translations when page first loaded
        i18n.init();

        const token = getAuthTokenPayload();
        if (!token) {
            this.redirectToLoginPage();
            return;
        }

        this.setExpiringInterval(token);
    }

    setExpiringInterval(token: TokenPayload) {
        const exp = new Date(0);
        exp.setUTCSeconds(token.expiry);
        const now = new Date();
        const expiringTime = exp.getTime() - now.getTime();
        const notificationTime = expiringTime - EXPIRING_NOTIFICATION_GAP;

        if (notificationTime > 0) {
            const notificationTimerId = setTimeout(() => {
                this.props.warning({
                    title: 'Auth-token expiration warning',
                    message: 'Your auth-token will be expired in ' + EXPIRING_NOTIFICATION_GAP / 1000 + ' seconds. You will need to login again.',
                    autoDismiss: false
                });
            }, notificationTime);

            this.setState({notificationTimerId});
        }

        if (expiringTime > 0) {
            const tokenExpiringTimerId = setTimeout(() => {
                this.redirectToLoginPage();
            }, expiringTime);

            this.setState({tokenExpiringTimerId});
        } else {
            this.redirectToLoginPage();
        }
    }

    redirectToLoginPage() {
        this.props.router.push('/login');
    }

    componentWillUnmount() {
        if (this.state.tokenExpiringTimerId) {
            clearTimeout(this.state.tokenExpiringTimerId);
        }
        if (this.state.notificationTimerId) {
            clearTimeout(this.state.notificationTimerId);
        }
    }

    getCurrentPage() {
        return pages.find((page) => page.path === this.props.location.pathname);
    }
  
    subPages: SubPage[] = getMenuItems();

    render() {
        const initalPageId = getInitialPageId(window.location.hash , this.subPages)

        return <I18nextProvider i18n={i18n}>
            <div id="app">
                <Notifications
                    notifications={this.props.notifications}/>

                <SimpleStackNavigation
                    appTitle="VCO"
                    appIcon='home'
                    subPages={this.subPages}
                    initialSubPage={this.subPages[initalPageId].id}
                    pathSignOut="/login"
                    history={this.props.router}
                />

                <div className="page-container">
                    <Navigation
                        currentPage={this.getCurrentPage()}/>

                    { React.Children.only(this.props.children) }
                </div>
            </div>
        </I18nextProvider>
    }

}

function getMenuItems(): SubPage[] {
    return pages.map((page, index) => {
        return {
            id: ""+index,
            label: page.title,
            icon: page.icon as any,
            path: page.path
        };
    });
}

export default connect(
    (state: StoreState) => ({
        notifications: state.notifications
    }),
    {warning}
)(App);
